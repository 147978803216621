































































































































import Vue from "vue";
import Component from "vue-class-component";
import { DepartureTripPattern, getDepartures } from "@/service/ferry";
import { DateTime } from "luxon";
import { Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["isFavorite"]),
  },
})
export default class RouteBook extends Vue {
  departures: null | DepartureTripPattern[] = null;
  fromPlace = "";
  toPlace = "";
  date = new Date();
  dialogNotice = false;
  notices: string[] = [];
  dialogSituation = false;
  situations: {
    advice: string;
    summary: string;
  }[] = [];

  get lineId(): string {
    return this.$route.params["id"];
  }

  @Watch("date", { immediate: true }) onDateChange() {
    const from = this.$route.params["fromId"];
    const to = this.$route.params["toId"];
    this.fromPlace = this.$route.params["fromStr"];
    this.toPlace = this.$route.params["toStr"];
    getDepartures(this.lineId, from, to, this.date).then(
      (departures) => (this.departures = departures)
    );
  }

  formatTime(str: string): string {
    const d = DateTime.fromISO(str);
    return d.toFormat("HH:mm");
  }

  get formattedDate(): string {
    return DateTime.fromJSDate(this.date).setLocale("no").toLocaleString({
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  }

  prevDay() {
    this.date = new Date(this.date.getTime() - 24 * 3600 * 1000);
    this.departures = null;
  }

  nextDay() {
    this.date = new Date(this.date.getTime() + 24 * 3600 * 1000);
    this.departures = null;
  }

  showNotice(msg: string[]) {
    this.notices = msg;
    this.dialogNotice = true;
  }

  showSituation(
    situations: {
      advice: string;
      summary: string;
    }[]
  ) {
    this.situations = situations;
    this.dialogSituation = true;
  }

  get isFavoriteLine() {
    return this.$store.getters.isFavorite(this.lineId);
  }

  toggleFavorite() {
    if (this.isFavoriteLine) {
      this.$store.commit("REMOVE_FAVORITE_LINE", this.lineId);
    } else {
      this.$store.commit("ADD_FAVORITE_LINE", this.lineId);
    }
  }
}
