

















import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import { google } from "google-maps";

export interface CoordinateWithName {
  lat: number;
  lng: number;
  name: string;
}

@Component
export default class PlacesAutocomplete extends Vue {
  @Prop() label!: string;
  @Prop() google!: google;
  placeholder = "";
  value: string | null = null;
  useGps = false;
  autocomplete: google.maps.places.Autocomplete | null = null;

  mounted() {
    const el = (this.$refs.input as Vue).$refs.input as HTMLInputElement;

    this.autocomplete = new this.google.maps.places.Autocomplete(el, {
      componentRestrictions: { country: "no" },
    });
    this.autocomplete?.addListener("place_changed", this.onPlaceChanged);
    el.placeholder = "";
    setTimeout(() => (el.placeholder = ""), 10);
  }

  onPlaceChanged() {
    const place = this.autocomplete?.getPlace();
    const latlng = place?.geometry?.location.toJSON();
    if (latlng !== undefined && this.value !== null)
      this.onPosition(latlng, place?.name || this.value);
  }

  onChange() {
    this.onPosition(null, "");
    if (this.useGps) {
      this.value = "";
      this.useGps = false;
    }
  }

  @Emit("position") onPosition(
    coord: { lat: number; lng: number } | null,
    name: string
  ): CoordinateWithName | null {
    if (coord === null) {
      return null;
    }
    return { ...coord, name: name };
  }

  getPosition() {
    this.useGps = !this.useGps;
    if (this.useGps) {
      this.onPosition(null, "");
      this.value = "Leter etter din posisjon";
      new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }).then((v) => {
        const coords = v.coords;
        this.onPosition(
          { lat: coords.latitude, lng: coords.longitude },
          "din posisjon"
        );
        this.value = "Din posisjon";
      });
    } else {
      this.value = "";
    }
  }
}
