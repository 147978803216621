import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Planlegging from "../views/Planlegging.vue";
import Lines from "@/views/Lines.vue";
import RoutePicker from "@/views/RoutePicker.vue";
import RouteBook from "@/views/RouteBook.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/lines",
  },
  {
    path: "/plan",
    name: "plan",
    component: Planlegging,
  },
  {
    path: "/lines",
    name: "line",
    component: Lines,
  },
  {
    path: "/favorites",
    name: "favorites",
    component: Lines,
    props: { favoritesOnly: true },
  },
  {
    path: "/line/:id",
    name: "route-picker",
    component: RoutePicker,
  },
  {
    path: "/route/:id/:fromId/:fromStr/:toId/:toStr",
    name: "route-book",
    component: RouteBook,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
