














import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import L from "leaflet";
import "leaflet.locatecontrol";
import { LMap, LPolyline, LTileLayer } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import "font-awesome/css/font-awesome.css";

@Component({ components: { LMap, LTileLayer, LPolyline } })
export default class OverviewMap extends Vue {
  @Prop() overviewPath!: [number, number][];
  isReady = false;
  url =
    "https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGlibm9yIiwiYSI6IklSeWlfZEkifQ.TGBGDeevZ9GQiX0h_V-iwg";
  attribution =
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, <a href="https://mapbox.com">Mapbox</a>, Google';

  boundingBox() {
    // @ts-ignore
    const mapEle = this.$refs.map as LMap;
    const map = mapEle.mapObject;
    const lc = L.control.locate({ flyTo: false, setView: false }).addTo(map);
    lc.start();
    const bbox = L.latLngBounds(this.overviewPath);
    map.fitBounds(bbox);
    this.isReady = true;
  }

  @Watch("overviewPath") onOverviewPathChange() {
    if (this.isReady) {
      const bbox = L.latLngBounds(this.overviewPath);
      // @ts-ignore
      const mapEle = this.$refs.map as LMap;
      const map = mapEle.mapObject;
      map.fitBounds(bbox);
    }
  }
}
