var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.timeTable !== null)?_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[(!_vm.isFirstFerry)?_c('th',[_vm._v("Ventetid")]):_vm._e(),_c('th',[_vm._v("Avreise")]),_c('th',[_vm._v("Ankomst")]),_c('th',[_vm._v("Rute")])])]),_c('tbody',_vm._l((_vm.timeTable),function(r,i){return _c('tr',{key:i,class:{
            toLate: _vm.isDepartureFocus
              ? r.startTime < _vm.startTime
              : r.endTime > _vm.arrivalTime,
            selected:
              _vm.selectedRoute !== undefined &&
              Math.abs(r.startTime - _vm.selectedRoute.startTime) < 1000,
          },on:{"click":function($event){return _vm.onRouteSelected(r)}}},[(!_vm.isFirstFerry)?_c('td',[_vm._v(" "+_vm._s(Math.floor((r.startTime - _vm.atStartPointTime) / 60000))+" min ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(r.startTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", }))+" ")]),_c('td',[_vm._v(" "+_vm._s(r.endTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", }))+" ")]),_c('td',[_vm._v(_vm._s(r.name))])])}),0)]),_vm._v(" Data made available by Entur. ")],1):_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }