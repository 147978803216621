import Vue from "vue";
import Vuex from "vuex";
import { Loader } from "google-maps";
import { Google } from "@/model";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

interface BaseState {
  google: Google | null;
  favoriteLines: string[];
}

const vuexLocal = new VuexPersistence<BaseState>({
  storage: window.localStorage,
  reducer: (state) => ({ ...state, google: null }),
});

export default new Vuex.Store<BaseState>({
  state: {
    google: null,
    favoriteLines: [],
  },
  getters: {
    isFavorite: (state) => (lineId: string) =>
      state.favoriteLines.includes(lineId),
  },
  mutations: {
    setGoogle: (state, google) => {
      state.google = google;
    },
    ADD_FAVORITE_LINE: (state, line) => {
      if (state.favoriteLines.includes(line)) return;
      state.favoriteLines.push(line);
    },
    REMOVE_FAVORITE_LINE: (state, line) => {
      state.favoriteLines = state.favoriteLines.filter((l) => l !== line);
    },
  },
  actions: {
    async getGoogle({ commit, state }) {
      if (state.google !== null) {
        return state.google;
      }
      console.log("Get google");
      const loader = new Loader("AIzaSyAKF_wB-i06Uv2MmzrN4Ue-hywe8ZqG1Ts", {
        libraries: ["places"],
      });
      return loader.load().then((google) => {
        commit("setGoogle", google);
        return google;
      });
    },
    addFavoriteLine({ commit }, line) {
      commit("ADD_FAVORITE_LINE", line);
    },
    removeFavoriteLine({ commit }, line) {
      commit("REMOVE_FAVORITE_LINE", line);
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
