




























import Vue from "vue";
import Component from "vue-class-component";
import { CarFerryLine, getCarFerryLines } from "@/service/ferry";
import { Prop } from "vue-property-decorator";

interface CarFerryLineWithDistance extends CarFerryLine {
  distanceMeter: number;
}

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInMeter(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371000; // Radius of the earth in meter
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

@Component
export default class Lines extends Vue {
  lines: null | CarFerryLine[] | CarFerryLineWithDistance[] = null;
  search = "";
  position: null | {
    coords: { latitude: number; longitude: number };
  } = null;
  @Prop({ required: false, default: false }) favoritesOnly!: boolean;

  mounted() {
    getCarFerryLines().then((lines) => (this.lines = lines));
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.sortLinesByPosition);
    }
  }

  sortLinesByPosition(position: {
    coords: { latitude: number; longitude: number };
  }) {
    this.position = position;
  }

  onLineClick(id: string) {
    this.$router.push({ name: "route-picker", params: { id: id } });
  }

  get filteredList() {
    if (this.lines === null) return [];
    let lines = [...this.lines];
    if (this.favoritesOnly) {
      lines = lines.filter((l) => this.$store.getters.isFavorite(l.id));
    }
    if (this.search !== "") {
      lines = lines.filter((v) => {
        if (v.name.toLowerCase().includes(this.search.toLowerCase()))
          return true;
        const inQuay = v.quays.map((v) =>
          v.name.toLowerCase().includes(this.search.toLowerCase())
        );
        return inQuay.some(Boolean);
      });
    }
    const position = this.position;
    if (position !== null) {
      lines = lines.map((l) => {
        const distances = l.quays.map((q) =>
          getDistanceFromLatLonInMeter(
            q.latitude,
            q.longitude,
            position.coords.latitude,
            position.coords.longitude
          )
        );
        const minDistance = Math.min(...distances);
        return { ...l, distanceMeter: minDistance };
      }) as CarFerryLineWithDistance[];
      lines.sort((a: CarFerryLine, b: CarFerryLine) =>
        (a as CarFerryLineWithDistance).distanceMeter <
        (b as CarFerryLineWithDistance).distanceMeter
          ? -1
          : 1
      );
    }
    return lines;
  }
}
