






















































































































































































































import { fullRoute, Leg } from "@/service/router";
import TimeTable, { TimeTableRow } from "@/components/TimeTable.vue";
import { DateTime } from "luxon";
import OverviewMap from "@/components/OverviewMap.vue";
import PlacesAutocomplete, {
  CoordinateWithName,
} from "@/components/PlacesAutocomplete.vue";
import LabeledInfo from "@/components/LabeledInfo.vue";
import Vue from "vue";
import { Google } from "@/model";
import Component from "vue-class-component";

@Component({
  components: {
    LabeledInfo,
    PlacesAutocomplete,
    OverviewMap,
    TimeTable,
  },
})
export default class Planlegging extends Vue {
  planedStartTime: string = new Date().toLocaleString();
  start: null | CoordinateWithName = null;
  stop: null | CoordinateWithName = null;
  steps: Leg[] = [];
  selectedRoute: (TimeTableRow | undefined)[] = [];
  names: string[] = [];
  startTime: Date[] = [];
  arrivalTime: Date[] = [];
  displayStartTime: Date[] = [];
  overviewPath: null | number[][] = null;
  google: null | Google = null;
  isDepartureFocus = true;
  minimumWaitingTimeMin = 5;
  openPanels: number[] = [];
  mounted() {
    this.$store.dispatch("getGoogle").then((google) => (this.google = google));
    const now = new Date();
    this.planedStartTime = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 16);
  }
  routeSelected(i: number, row: TimeTableRow) {
    this.selectedRoute[i] = row;
    this.updateDisplayTime();
    this.openPanels = [];
  }
  getStart(d: CoordinateWithName | null) {
    this.start = d;
  }
  getStop(d: CoordinateWithName | null) {
    this.stop = d;
  }
  calculate() {
    if (this.start === null || this.stop === null || this.google === null)
      return;
    fullRoute(
      this.start,
      this.stop,
      this.google,
      new Date(this.planedStartTime)
    ).then((v) => {
      if (this.start === null || this.stop === null) return;
      this.steps = v.steps;
      this.selectedRoute = [];
      this.names[0] = this.start.name;
      this.names[this.steps.length] = this.stop.name;
      this.overviewPath = v.overviewPath;
      this.updateDisplayTime();
      setTimeout(this.optimizeRoute, 2000);
    });
  }

  optimizeRoute() {
    if (this.selectedRoute === undefined) return;
    const ferries = this.$refs.ferry as TimeTable[];

    if (this.isDepartureFocus) {
      let ferryId = 0;
      let startTime = DateTime.fromISO(this.planedStartTime)
        .toJSDate()
        .getTime();
      this.steps.forEach((r, i) => {
        if (r.isFerry && ferries) {
          const timeTable = ferries[ferryId].timeTable as TimeTableRow[];
          const row = timeTable.find((r) => r.startTime.getTime() > startTime);
          this.selectedRoute[i] = row;
          if (row !== undefined) {
            startTime = row.endTime.getTime();
          } else {
            startTime += r.duration * 1000;
          }
          ferryId += 1;
        } else {
          startTime +=
            r.duration * 1000 + this.minimumWaitingTimeMin * 60 * 1000;
        }
      });
    } else {
      if (this.steps === null) return;
      let ferryId = ferries.length - 1;
      let startTime = DateTime.fromISO(this.planedStartTime)
        .toJSDate()
        .getTime();
      const steps = [...this.steps];
      steps.reverse();

      steps.forEach((r, j) => {
        const i = steps.length - 1 - j;
        if (r.isFerry && ferries) {
          const timeTable = [...(ferries[ferryId].timeTable as TimeTableRow[])];
          timeTable.reverse();
          const row = timeTable.find((r) => r.endTime.getTime() < startTime);
          this.selectedRoute[i] = row;
          if (row !== undefined) {
            startTime = row.startTime.getTime();
          } else {
            startTime -= r.duration * 1000;
          }
          ferryId -= 1;
        } else {
          startTime -= r.duration * 1000;
          if (j !== 0) {
            startTime -= this.minimumWaitingTimeMin * 1000 * 60;
          }
        }
      });
    }
    this.updateDisplayTime();
  }
  updateDisplayTime() {
    this.selectedRoute.forEach((row, i) => {
      if (row === undefined) return;
      this.names[i] = row.startName;
      this.names[i + 1] = row.endName;
    });

    if (this.isDepartureFocus) {
      this.updateStartTime();
    } else {
      this.updateEndTime();
    }
  }

  updateStartTime() {
    let prevStartTime = DateTime.fromISO(this.planedStartTime)
      .toJSDate()
      .getTime();
    const startTime = [new Date(prevStartTime)];
    this.steps.forEach((r, i) => {
      const row = this.selectedRoute[i];
      if (row !== undefined) {
        prevStartTime = row.endTime.getTime();
      } else {
        prevStartTime += r.duration * 1000;
      }
      startTime.push(new Date(prevStartTime));
    });

    const displayStartTime = [...startTime];
    if (this.selectedRoute[1] !== undefined) {
      displayStartTime[1] = new Date(
        this.selectedRoute[1].startTime.getTime() -
          this.minimumWaitingTimeMin * 60000
      );
      displayStartTime[0] = new Date(
        displayStartTime[1].getTime() - this.steps[0].duration * 1000
      );
    }
    this.displayStartTime = displayStartTime;
    this.startTime = startTime;
  }

  updateEndTime() {
    let prevStartTime = DateTime.fromISO(this.planedStartTime)
      .toJSDate()
      .getTime();
    let endTime = [new Date(prevStartTime)];
    this.steps.reverse().forEach((r, j) => {
      const i = this.steps.length - 1 - j;

      const row = this.selectedRoute[i];
      if (row !== undefined) {
        prevStartTime = row.startTime.getTime();
      } else {
        prevStartTime -= r.duration * 1000;
      }
      endTime.push(new Date(prevStartTime));
    });
    const startTime = endTime.pop() as Date;
    endTime = endTime.reverse();

    this.arrivalTime = endTime;

    prevStartTime = startTime.getTime();
    const displayStartTime = [startTime];
    this.steps.reverse().forEach((r, i) => {
      const row = this.selectedRoute[i];
      if (row !== undefined) {
        prevStartTime = row.endTime.getTime();
      } else {
        prevStartTime += r.duration * 1000;
      }
      displayStartTime.push(new Date(prevStartTime));
    });
    this.displayStartTime = displayStartTime;
  }
}
