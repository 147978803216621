


































import Vue from "vue";
import CookieLaw from "vue-cookie-law";

export default Vue.extend({
  name: "App",

  components: { CookieLaw },
  data: () => ({
    drawer: false,
  }),
});
