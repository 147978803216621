

























































import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { getFerriesFromTo, JourneyLeg } from "@/service/ferry";
import { DateTime } from "luxon";

export interface Row {
  startTime: Date;
  endTime: Date;
  name: string;
}

export interface TimeTableRow {
  startTime: Date;
  startName: string;
  endTime: Date;
  endName: string;
  name: string;
}

@Component
export default class TimeTable extends Vue {
  @Prop() startPosition!: google.maps.LatLng;
  @Prop() endPosition!: google.maps.LatLng;
  @Prop() startTime!: Date;
  @Prop() atStartPointTime!: Date;
  @Prop() arrivalTime!: Date;
  @Prop() isDepartureFocus!: boolean;
  @Prop() isFirstFerry!: boolean;
  @Prop() selectedRoute: Row | undefined;
  public timeTable: TimeTableRow[] | null = null;

  mounted() {
    this.updateFerries();
  }

  private updateFerries() {
    let time: Date;
    if (this.isDepartureFocus) {
      time = new Date(this.startTime.getTime() - 1000 * 3600);
    } else {
      time = new Date(this.arrivalTime.getTime() + 1000 * 3600);
    }

    getFerriesFromTo(
      time,
      this.startPosition,
      this.endPosition,
      !this.isDepartureFocus
    ).then((v) => {
      const legs = v.trip.tripPatterns
        .filter((v) => v.walkDistance < 1000)
        .filter((v) => v.legs.length <= 3)
        .map((v) => {
          const legs = v.legs.filter(
            (l) =>
              l.mode === "water" &&
              l.line.transportSubmode !== "highSpeedPassengerService"
          );
          if (legs.length !== 1) {
            return;
          }
          return legs[0];
        })
        .filter((v) => v) as JourneyLeg[];
      if (!this.isDepartureFocus) {
        legs.reverse();
      }
      this.timeTable = legs.map((leg) => {
        return {
          startTime: DateTime.fromISO(leg.expectedStartTime).toJSDate(),
          startName: leg.serviceJourneyEstimatedCalls[0].quay.name,
          endTime: DateTime.fromISO(leg.expectedEndTime).toJSDate(),
          endName:
            leg.serviceJourneyEstimatedCalls[
              leg.serviceJourneyEstimatedCalls.length - 1
            ].quay.name,
          name: leg.line.name,
        };
      });
    });
  }

  @Watch("arrivalTime") onStartTimeChange(value: Date, oldValue: Date) {
    if (value !== oldValue) {
      this.updateFerries();
    }
  }
  @Watch("startTime") onArrivalTimeChange(value: Date, oldValue: Date) {
    if (value !== oldValue) {
      this.updateFerries();
    }
  }

  @Emit("route-selected") onRouteSelected(r: Row) {
    return r;
  }
}
