import createEnturService from "@entur/sdk";
import { DateTime } from "luxon";

function parseJourney(data: JourneyPlanner) {
  return data.stopPlace.estimatedCalls.map((trip) => {
    return {
      etd: DateTime.fromISO(trip.expectedDepartureTime).toJSDate(),
      destination: trip.destinationDisplay.frontText,
    };
  });
}

const service = createEnturService({
  clientName: "Kartmannen AS-ferjetider.no",
});

export function getFerries(startTime: Date, from: string) {
  const query = `
{
  stopPlace(id: "${from}") {
    name
    estimatedCalls(timeRange: 86400, numberOfDepartures: 30, whiteListedModes: [water], startTime: "${startTime.toISOString()}") {     
      realtime
      expectedDepartureTime
      destinationDisplay {
        frontText
      }
    }
  }
}
`;

  return service
    .queryJourneyPlanner<JourneyPlanner>(query, {})
    .then(parseJourney);
}

interface JourneyPlanner {
  stopPlace: {
    name: string;
    estimatedCalls: {
      realtime: boolean;
      expectedDepartureTime: string;
      destinationDisplay: {
        frontText: string;
      };
    }[];
  };
}

export function getFerriesFromTo(
  startTime: Date,
  from: google.maps.LatLng,
  to: google.maps.LatLng,
  arriveBy: boolean
): Promise<JourneyPlannerToFromResponse> {
  const query = `
{
  trip(
    dateTime: "${startTime.toISOString()}"
    arriveBy: ${arriveBy}
    from: {name: "Start"
    coordinates: {
      latitude: ${from.lat()}
      longitude:${from.lng()}
    }}
    to: {
      coordinates: {
      latitude: ${to.lat()}
      longitude:${to.lng()}
    }
      name:"Stop"
    }
    numTripPatterns: 15
  )
  {
    tripPatterns {
      startTime
      endTime
      duration
      walkDistance

          legs {
            expectedStartTime
            expectedEndTime
            mode
            distance
            line {
              id
              publicCode
              name
              transportMode
              transportSubmode
              authority{
                name
              }
            }
            serviceJourneyEstimatedCalls {
              quay {
                name
              }
            }
          }
    }
  }
}
`;

  return service.queryJourneyPlanner<JourneyPlannerToFromResponse>(query, {});
}

export interface JourneyLeg {
  mode: string;
  distance: string;
  expectedStartTime: string;
  expectedEndTime: string;
  line: {
    id: string;
    publicCode: string;
    name: string;
    transportMode: string;
    transportSubmode: string;
    authority: {
      name: string;
    };
  };
  serviceJourneyEstimatedCalls: {
    quay: {
      name: string;
    };
  }[];
}

export interface JourneyPlannerToFromResponse {
  trip: {
    tripPatterns: {
      startTime: string;
      endTime: string;
      duration: number;
      walkDistance: number;
      legs: JourneyLeg[];
    }[];
  };
}

export interface CarFerryLine {
  id: string;
  name: string;
  transportSubmode: string;
  quays: {
    id: string;
    longitude: number;
    latitude: number;
    name: string;
  }[];
}

export function getCarFerryLines(): Promise<CarFerryLine[]> {
  const query = `
{
  lines(transportModes: water) {
    id
    name
    transportSubmode
    quays {
      id
      longitude
      latitude
      name
    }
  }
}
`;

  return service
    .queryJourneyPlanner<{ lines: CarFerryLine[] }>(query, {})
    .then((res) => {
      return res.lines.filter((l) => l.transportSubmode === "localCarFerry");
    });
}

export function getCarFerryLine(id: string): Promise<CarFerryLine> {
  const query = `
{
  line(id: "${id}") {
    id
    name
    transportSubmode
    quays {
      id
      longitude
      latitude
      name
    }
  }
}
`;

  return service
    .queryJourneyPlanner<{ line: CarFerryLine }>(query, {})
    .then((r) => r.line);
}

export interface DepartureTripPatternResponse {
  aimedStartTime: string;
  aimedEndTime: string;
  expectedStartTime: string;
  expectedEndTime: string;
  legs: {
    realtime: boolean;
    situations: {
      id: string;
      advice: {
        language: string;
        value: string;
      }[];
      summary: {
        language: string;
        value: string;
      }[];
    }[];
    serviceJourney: {
      notices: {
        id: string;
        publicCode: string;
        text: string;
      }[];
    };
  }[];
}

export interface DepartureTripPattern {
  aimedStartTime: string;
  aimedEndTime: string;
  expectedStartTime: string;
  expectedEndTime: string;
  realtime: boolean;
  situations: {
    advice: string;
    summary: string;
  }[];
  notices: string[];
}

export interface DepartureResponse {
  trip: {
    tripPatterns: DepartureTripPatternResponse[];
  };
}

export function getDepartures(
  lineId: string,
  from: string,
  to: string,
  date: Date
): Promise<DepartureTripPattern[]> {
  const start = DateTime.fromJSDate(date).setZone("Europe/Oslo").startOf("day");
  const end = start.plus({ days: 1 });
  const d = start.toISO();
  const query = `{
  trip(
    from: {place: "${from}"}
    to: {place: "${to}"}
    whiteListed: {lines: "${lineId}"}
    dateTime: "${d}"
    numTripPatterns: 200  
  ) {
    tripPatterns {
      aimedStartTime
      aimedEndTime
      expectedStartTime
      expectedEndTime
      legs {
        realtime
        situations {
          id
          infoLinks {
            uri
            label
          }
          advice {
            language
            value
          }
          summary {
            language
            value
          }
        }
        serviceJourney {
          notices {
            id
            publicCode
            text
          }
        }
      }
    }
  }
}`;
  return service.queryJourneyPlanner<DepartureResponse>(query, {}).then((r) => {
    return r.trip.tripPatterns
      .filter((t) => DateTime.fromISO(t.aimedStartTime) <= end)
      .map(
        (v) =>
          ({
            aimedStartTime: v.aimedStartTime,
            aimedEndTime: v.aimedEndTime,
            expectedStartTime: v.expectedStartTime,
            expectedEndTime: v.expectedEndTime,
            situations: v.legs.flatMap((l) =>
              l.situations.map((s) => ({
                advice: s.advice[0].value,
                summary: s.summary[0].value,
              }))
            ),
            notices: v.legs.flatMap((l) =>
              l.serviceJourney.notices.flatMap((n) => n.text)
            ),
            realtime: v.legs.map((l) => l.realtime).some(Boolean),
          } as DepartureTripPattern)
      );
  });
}
