import polyline from "@mapbox/polyline";
import { google } from "google-maps";

export interface FullPathResult {
  steps: Leg[];
  overviewPath: number[][];
}

export function fullRoute(
  start: { lat: number; lng: number },
  stop: { lat: number; lng: number },
  google: google,
  departureTime: Date,
  trafficModel: google.maps.TrafficModel = google.maps.TrafficModel.PESSIMISTIC
): Promise<FullPathResult> {
  const directionsService = new google.maps.DirectionsService();
  return new Promise<google.maps.DirectionsResult>((resolve) => {
    directionsService.route(
      {
        origin: new google.maps.LatLng(start.lat, start.lng),
        destination: new google.maps.LatLng(stop.lat, stop.lng),
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: departureTime,
          trafficModel: trafficModel,
        },
      },
      resolve
    );
  }).then((v: google.maps.DirectionsResult) => {
    const steps: Leg[] = v.routes[0].legs[0].steps.reduce<Leg[]>(
      (result, _step) => {
        const step = _step as unknown as Step;
        const isFerry = step.maneuver == "ferry";
        if (result.length === 0) {
          result.push({
            start: step.start_location,
            stop: step.end_location,
            isFerry: isFerry,
            duration: step.duration.value,
            path: step.path,
          });
        } else {
          const lastLeg = result[result.length - 1];
          if (lastLeg.isFerry !== isFerry) {
            result.push({
              start: step.start_location,
              stop: step.end_location,
              isFerry: isFerry,
              duration: step.duration.value,
              path: step.path,
            });
          } else {
            lastLeg.duration += step.duration.value;
            lastLeg.stop = step.end_location;
          }
        }
        return result;
      },
      []
    );
    return {
      steps: steps,
      overviewPath: polyline.decode(v.routes[0].overview_polyline),
    };
  });
}

interface Step extends google.maps.BaseDirectionsStep {
  maneuver: string;
}

export interface Leg {
  start: google.maps.LatLng;
  stop: google.maps.LatLng;
  path: google.maps.LatLng[];
  isFerry: boolean;
  duration: number;
}
