
































import Vue from "vue";
import Component from "vue-class-component";
import { CarFerryLine, getCarFerryLine } from "@/service/ferry";

@Component
export default class RoutePicker extends Vue {
  line: null | CarFerryLine = null;
  departure: null | string = null;

  mounted() {
    const lineId = this.$route.params["id"];
    getCarFerryLine(lineId).then((line) => (this.line = line));
  }

  selectDeparture(id: string) {
    this.departure = id;
    if (this.line?.quays.length == 2) {
      const a = this.line.quays.find((q) => q.id !== this.departure);
      if (a === undefined) return;
      this.selectArrival(a.id);
    }
  }

  selectArrival(id: string) {
    const lineId = this.$route.params["id"];
    if (this.departure === null || this.line === null) return;
    const fromStr = this.line.quays.find((v) => v.id === this.departure)?.name;
    const toStr = this.line.quays.find((v) => v.id === id)?.name;

    if (fromStr === undefined || toStr === undefined) return;
    this.$router.push({
      name: "route-book",
      params: {
        id: lineId,
        fromId: this.departure,
        fromStr: fromStr,
        toId: id,
        toStr: toStr,
      },
    });
  }
}
