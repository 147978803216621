<template>
  <div class="v-input theme--light">
    <div class="v-input__control">
      <div class="v-messages theme--light">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">{{ label }}</div>
        </div>
      </div>
      <div class="v-input__slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "labeled-info",
  props: {
    label: String
  }
};
</script>

<style scoped></style>
